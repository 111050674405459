import React from 'react';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components/macro';
import {
  AccordionDetails,
  AccordionSummary,
  Accordion,
} from '@material-ui/core';

import { ContentBlock, Headline, Text, List } from '../components';

import {
  black,
  darkGrey,
  mediumGrey,
  lightGrey,
  transition,
} from '../utils/sharedStyles';

import { faqs } from '../data';

const StyledAccordion = styled(Accordion)`
  background-color: ${mediumGrey};
  font: normal normal 800 16px/21px Heebo;
  margin-bottom: 1.5rem;
  box-shadow: none;
  ${transition}

  &:hover {
    background-color: ${darkGrey};
  }
`;

const StyledSummary = styled(AccordionSummary)`
  background-color: rgb(230, 227, 221);
  min-height: 52px !important;
  max-height: 52px;
  font-family: Heebo;
  font-weight: 800;
  font-size: 15px;
  color: rgb(77, 70, 66);
  text-transform: uppercase;

  &:hover {
    background-color: rgb(215, 211, 204);
  }
`;

const Details = styled(AccordionDetails)`
  background-color: ${lightGrey};
  padding: 16px;
`;

const customFont = css`
  font: normal normal 300 17px/20px Heebo;
`;

const StyledLink = styled(Link)`
  color: ${black};
`;

const FAQ = () => (
  <ContentBlock pb={8}>
    <Headline semiBold>Frequently Asked Questions</Headline>
    <Text>
      Check the answers to frequently asked questions. Feel free to{' '}
      <StyledLink to="/contact">contact me</StyledLink>, if you are still
      missing information after reading the FAQ.
    </Text>
    <ContentBlock>
      {faqs.map(faq => (
        <StyledAccordion key={faq.id}>
          <StyledSummary>{faq.question}</StyledSummary>
          <Details>
            <List items={faq.details} font={customFont} />
          </Details>
        </StyledAccordion>
      ))}
    </ContentBlock>
  </ContentBlock>
);

export default FAQ;
